import React, { useEffect, useState } from 'react';
import { useTheme } from '@swibeco/shared';
import { Box } from '@chakra-ui/react';
import { useIsMobile, useIsTablet, usePlatform } from '@swibeco/core';
import TagManager from 'react-gtm-module';
import { AnalyticsEvents } from '@swibeco/types';
import HeaderItem from './HeaderItem';
import { ReactComponent as PartnerPromoIcon } from '../../assets/images/partner-promo.svg';
import PartnerOffersPanel from './PartnerOffersPanel';
import { useScrollBlock } from '../../hooks';

const PartnerOffers = () => {
  const environment = usePlatform(window);
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const [isOpen, setOpen] = useState<false | 'mobile' | 'desktop'>(false);
  const toggleOpen = (newValue?: boolean) =>
    setOpen((val) => {
      if (val === false || newValue === false) {
        return isTablet || isMobile ? 'mobile' : 'desktop';
      }

      return false;
    });
  useEffect(() => {
    if ((isMobile || isTablet) && isOpen === 'desktop') {
      setOpen('mobile');
    }
    if (isTablet && isOpen === 'desktop') {
      setOpen('mobile');
    }
    if (isMobile && isOpen === 'desktop') {
      setOpen('mobile');
    }
    if (!isMobile && !isTablet && isOpen === 'mobile') {
      setTimeout(() => {
        setOpen('desktop');
      }, 1000);
    }
  }, [isMobile, isTablet, isOpen, setOpen]);

  const theme = useTheme();
  const { allowScroll, blockScroll } = useScrollBlock();
  useEffect(() => {
    if (isOpen !== false) {
      blockScroll();
    } else {
      allowScroll();
    }
    return () => {
      allowScroll();
    };
  }, [isOpen, allowScroll, blockScroll]);

  const handleClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: AnalyticsEvents.CLICK_OFFERS,
        environment,
      },
    });
    toggleOpen();
  };

  return (
    <HeaderItem noBorder>
      <Box
        cursor="pointer"
        display="flex"
        alignItems="center"
        onClick={handleClick}
        data-testid="wishlist-btn"
        aria-label="User Wishlist"
      >
        <PartnerPromoIcon color={theme.colors.default.black} />
        <span className="background" />
      </Box>
      <PartnerOffersPanel isOpen={isOpen} setIsOpen={setOpen} />
    </HeaderItem>
  );
};

export default PartnerOffers;
