import { styled } from '@swibeco/shared';

export const Wrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1100;
`;
export const Header = styled.header`
  background-color: ${({ theme }) => theme.colors.default.white};
  width: 100%;
  height: 70px;
  display: flex;
  box-shadow: 0 3px 6px #00000029;
  align-items: center;
`;
