import { UserType, UserTypeEnum } from '@swibeco/types';
import React, { useEffect, useState } from 'react';
import { useScrollBlock } from '../../../hooks';
import ProfileSwipointsBalance from '../ProfileSwipointsBalance';
import * as Styles from './styles/UserProfile.styles';
import avatarDefault from './images/avatar_default.svg';
import Panel from './Panel/Panel';

type UserProfileProps = {
  userData: UserType | undefined;
  clearUser?: () => void;
};

const UserProfile = ({ userData }: UserProfileProps) => {
  const [isOpen, setOpen] = useState(false);
  const toggleOpen = (newValue?: boolean) => setOpen(newValue || !isOpen);
  const showPlusDetails = userData?.type === UserTypeEnum.PLUS;
  const { allowScroll, blockScroll } = useScrollBlock();
  useEffect(() => {
    if (isOpen) {
      blockScroll();
    } else {
      allowScroll();
    }
    return () => {
      allowScroll();
    };
  }, [isOpen, allowScroll, blockScroll]);

  return (
    <Styles.HeaderItemUserProfile noBorder>
      <div className="position-relative">
        <Styles.IconWrapper
          onClick={() => toggleOpen()}
          data-testid="header-userprofile-toggle"
        >
          {showPlusDetails && <Styles.PlusUserBorder />}
          <Styles.ProfilePicture
            src={userData!.profilePicture || avatarDefault}
          />
          <ProfileSwipointsBalance hasRef userData={userData} />
          <span className="background" />
        </Styles.IconWrapper>
        <Panel isOpen={isOpen} setIsOpen={setOpen} userData={userData} />
      </div>
    </Styles.HeaderItemUserProfile>
  );
};

export default UserProfile;
