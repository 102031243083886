import React from 'react';
import { Menu, MenuButton, MenuList, MenuItem, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLanguageSwitcher, usePlatform } from '@swibeco/core';
import { ChevronDirection } from '@swibeco/ui';
import { useColorVariant } from '@swibeco/shared';
import TagManager from 'react-gtm-module';
import { AnalyticsEvents } from '@swibeco/types';
import * as Styles from './styles/LanguageSwitcher.styles';
import HeaderItem from './HeaderItem';

type LanguageSwitcherProps = { show: boolean };

const LanguageSwitcher = ({ show }: LanguageSwitcherProps) => {
  const { locales, setLocale, currentLocale } = useLanguageSwitcher();
  const { t } = useTranslation();
  const primaryDarkVariant = useColorVariant('primary', 'dark');
  const environment = usePlatform(window);

  const changeLanguage = (locale: string) => {
    setLocale(locale);
    TagManager.dataLayer({
      dataLayer: {
        event: AnalyticsEvents.LANGUAGE_CHANGED,
        environment,
        language: locale.toUpperCase(),
      },
    });
  };

  return show ? (
    <HeaderItem noBorder className="pr-md-0" data-testid="language-switcher">
      <Box display="flex" justifyContent="end">
        <Menu placement="bottom">
          {({ isOpen }) => (
            <>
              <MenuButton>
                <Styles.DropdownToggle>
                  <Box
                    padding="3px 0"
                    borderRadius="5px"
                    color="default.black"
                    fontWeight="500"
                    display="flex"
                    alignItems="center"
                    _hover={{ bg: 'none' }}
                    _focus={{ bg: 'none' }}
                    transition="background-color 0.2s ease-in-out"
                    data-testid="current-locale"
                  >
                    {currentLocale?.toUpperCase()}
                    <Styles.Chevron
                      color={primaryDarkVariant}
                      direction={
                        isOpen ? ChevronDirection.Up : ChevronDirection.Down
                      }
                    />
                  </Box>
                  <span className="background" />
                </Styles.DropdownToggle>
              </MenuButton>
              <MenuList borderRadius="none" p={0} minW="min-content">
                {locales.map((mappedLocale) => (
                  <MenuItem
                    cursor="pointer"
                    as="li"
                    key={mappedLocale}
                    padding="0.25rem 0.9rem"
                    onClick={() => changeLanguage(mappedLocale)}
                    fontWeight={
                      mappedLocale === currentLocale ? 700 : undefined
                    }
                  >
                    <span className="d-lg-none">
                      {mappedLocale.toUpperCase()}
                    </span>
                    <span className="d-none d-lg-inline-block">
                      {t(
                        `core.language_switcher.locale.${mappedLocale.toLowerCase()}`
                      )}
                    </span>
                  </MenuItem>
                ))}
              </MenuList>
            </>
          )}
        </Menu>
      </Box>
    </HeaderItem>
  ) : null;
};

export default LanguageSwitcher;
